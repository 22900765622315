<template>
  <div class="textbox">
    <div class="bottombox">
      <div class="bottom_left">
        <div class="tabbox">
          <div class="tabtitle">个人中心</div>

          <div
            class="tabbtn"
            :class="i == 1 || i == 3 ? 'active' : ''"
            @click="gettags(1)"
          >
            - 个人首页
          </div>

          <div class="tabtitle">安全中心</div>

          <div
            class="tabbtn"
            :class="i == 2 || i == 4 || i == 6 ? 'active' : ''"
            @click="gettags(2)"
          >
            - 账户设置
          </div>
        </div>
      </div>
      <!-- 个人信息 -->
      <div class="bottom_right" v-if="i == 1">
        <div class="huanying" v-if="compObj.Type == 0">
          <div>尊敬的会员：{{ compObj.Name }}</div>

          欢迎您登录浐灞产业联盟，您的账号目前还是个人会员，如果您希望使用平台提供的企业服务，请点击下方按钮，认证成为企业会员!
          <div class="upbox">
            <span
              v-if="compObj.Type == 0 && obj.State != 0 && obj.State != -1"
              @click="getComVIP"
              >成为企业会员</span
            >
            <span v-if="obj.State == 0">企业会员正在审核</span>
            <span v-if="obj.State == -1" @click="getComVIP"
              >再次申请企业会员</span
            >
          </div>
        </div>

        <div
          class="huanying"
          v-if="compObj.Type == 1 && (obj.State == -1 || obj.State == 0)"
        >
          <div>尊敬的会员：{{ compObj.Name }}</div>

          <span v-if="obj.State == -1"
            >欢迎您登录浐灞产业联盟，您的企业账号已被<span
              style="
                color: red;
                font-size: 17px;
                font-weight: bold;
                padding: 0px 3px;
              "
              >禁用</span
            >，如果您希望使用平台提供的企业服务，请点击下方按钮重新认证!</span
          >
          <span v-if="obj.State == 0"
            >欢迎您登录浐灞产业联盟，您的企业账号正在<span
              style="
                color: green;
                font-size: 17px;
                font-weight: bold;
                padding: 0px 3px;
              "
              >审核中</span
            >，请稍后再试!</span
          >
          <div class="upbox">
            <span v-if="obj.State == 0">企业会员正在审核</span>
            <span v-if="obj.State == -1" @click="getComVIP"
              >重新认证企业会员</span
            >
          </div>
        </div>

        <div class="smalltitle">个人基本信息</div>
        <div class="userinforbox">
          <div class="userlogo">
            <span>用户头像：</span>
            <img v-if="compObj.Logo" :src="compObj.Logo" alt="" />
            <img v-else src="../assets/img/girl.png" alt="" />
          </div>
          <div class="username">
            <span>用户姓名：</span> <i>{{ compObj.Name }}</i>
          </div>
          <div class="username">
            <span>性别：</span> <i>{{ compObj.Sex }}</i>
          </div>
          <div class="username">
            <span>联系方式：</span>{{ compObj.Mobile }}
          </div>
          <div class="username"><span>座机：</span>{{ compObj.Tel }}</div>
          <div class="username"><span>个人邮箱：</span>{{ compObj.EMail }}</div>
          <div class="username"><span>身份证号：</span>{{ compObj.No }}</div>
          <div class="username" style="width: 100%; line-height: 40px">
            <span>用户简介：</span> <i>{{ compObj.Description }}</i>
          </div>
        </div>
        <div class="inforedint">
          <span @click="gettags(3)">编辑</span>
        </div>
      </div>
      <!-- 账户信息 -->
      <div class="bottom_right" v-if="i == 2">
        <div class="smalltitle">账户设置</div>
        <div class="password">
          <div class="left">
            <div class="left_title">修改密码</div>
            <div class="left_text">
              安全性高的密码可以使帐号更安全。建议您设置一个包含字母，符号或数字中至少两项且长度超过6位的密码。
            </div>
          </div>
          <div class="right"><span @click="gettags(4)">修改</span></div>
        </div>

        <div class="password">
          <div class="left">
            <div class="left_title">绑定微信</div>
            <div class="left_text" v-if="!isWechat">您还没有绑定微信</div>
            <div class="left_text" v-else>已绑定微信</div>
          </div>
          <div class="right" v-if="!isWechat">
            <span @click="getWeChat(6)">绑定</span>
          </div>
          <div class="right" v-else>
            <span @click="openWeChat()">解绑</span>
          </div>
        </div>
      </div>
      <div class="bottom_right" v-if="i == 6">
        <div class="huanying" v-if="!isWechat">
          请使用微信扫一扫扫描下方二维码绑定账号
        </div>
        <div class="heard" v-else>
          <span @click="gettags(2)"
            ><img src="../assets/img/back.png" alt="" /> 返回</span
          >
        </div>
        <img class="img" v-if="!isWechat" :src="WechatImg" alt="" />
        <img
          class="imga"
          v-else
          src="../assets/img/272db759c79a55517e77aec761827e9.png"
          alt=""
        />
      </div>
      <!-- 修改个人信息 -->
      <div class="bottom_right" v-if="i == 3">
        <div class="smalltitle">编辑个人信息</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label="用户姓名" prop="Name">
            <el-input v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="Sex">
            <el-select v-model="ruleForm.Sex" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码" prop="Mobile">
            <el-input v-model="ruleForm.Mobile"></el-input>
          </el-form-item>
          <el-form-item label="座机号码" prop="Tel">
            <el-input v-model="ruleForm.Tel"></el-input>
          </el-form-item>
          <el-form-item label="个人邮箱" prop="EMail">
            <el-input v-model="ruleForm.EMail"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="No">
            <el-input v-model="ruleForm.No"></el-input>
          </el-form-item>
          <!-- 用户头像  base64  -->
          <el-form-item label="上传头像 建议尺寸80x80" :required="true">
             <span slot="label" >上传头像<span style="color:red;font-size:12px"> (建议尺寸80x80)</span></span>
            <template>
              <div v-if="LogoList.length > 0">
                <div
                  class="demo-upload-list"
                  :key="index"
                  v-for="(item, index) in LogoList"
                >
                  <template style="height: 150px; width: 150px">
                    <div class="demo-upload-list-cover">
                      <img class="imputImg" :src="item.url" />
                      <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                      <i
                        class="el-icon-circle-close delimg"
                        @click="RemoveLogo(item)"
                      ></i>
                    </div>
                  </template>
                </div>
              </div>
              <!-- <Upload> -->
              <el-upload
                ref="upload"
                v-if="LogoList.length == 0"
                :before-upload="AddFileLogo"
                type="drag"
                action=""
                style="display: inline-block; width: 58px"
              >
                <div style="width: 58px; height: 58px; line-height: 58px">
                  <i class="el-icon-camera-solid"></i>
                  <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
                </div>
              </el-upload>
              <!-- </Upload> -->
            </template>
          </el-form-item>

          <el-form-item label="个人简介" prop="Description">
            <el-input
              type="textarea"
              maxlength="200"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入个人简介"
              v-model="ruleForm.Description"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
            <el-button @click="cancleserve(1)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 修改密码 -->
      <div class="bottom_right" v-if="i == 4">
        <div class="smalltitle">修改密码</div>
        <el-form status-icon class="demo-ruleForm">
          <el-form-item label="旧密码">
            <el-input v-model="OldPassword"></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input
              type="text"
              v-model="Password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="确认新密码" prop="checkPass">
            <el-input
              type="text"
              v-model="ruleForms.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item> -->

          <el-form-item>
            <el-button type="primary" @click="changePassword('ruleForm')"
              >确定</el-button
            >
            <el-button @click="cancleserve(2)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// 公共样式
import EditorBar from "../components/wangeditor";
import timeFormat from "../util/time.js";
var isvalidPhone = function (str) {
  const reg = /^(1[3456789]\d{9})$|^(0\d{2,3}-\d{7,8})$/;
  return reg.test(str);
};
var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!isvalidPhone(value)) {
    callback(new Error("请输入正确的11位手机号码或座机号码"));
  } else {
    callback();
  }
};
var checkNumber = (rule,value,callback)=>{
   if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)&&value) {
        callback(new Error("你输入的身份证长度或格式错误"));
      }else {
        callback()
      }
}
export default {
  components: {
    EditorBar,
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      DeleteImgSysID: "",
      checkUpload: 1,
      imgFilesList: [],
      FileList: [],
      LogoList: [],
      dialogImageUrl: [],
      compObj: [],
      options: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],
      value: "",
      index: 1,
      i: 1,
      dialogVisible: false,
      rules: {
        Name: [
          // 用户姓名
          { required: true, message: "请输姓名或昵称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "2 到 20 个字符",
            trigger: "blur",
          },
        ],
        // 性别
        Sex: [],
        // 电话
        Mobile: [{ required: true, trigger: "blur", validator: validPhone }],
        No:[{  trigger: ["blur", "change"], validator: checkNumber },
        ],

        // 邮箱
        EMail: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      ruless: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },

      ruleForm: {
        dialogImageUrl: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },

      OldPassword: "",
      Password: "",
      // 企业类型列表
      companyType: null,
      // 企业标签
      companyTags: null,

      WechatImg: "",
      timeONce: null,
      isWechat: false,
      isCarre: "",
      obj: {},
    };
  },

  methods: {
    // 修改密码
    changePassword() {
      let that = this;
      let OldPassword = that.OldPassword;
      let Password = that.Password;
      if (OldPassword.trim().length < 6) {
        that.$message.error("请正确输入旧密码");
        return;
      }
      if (Password.trim().length < 6) {
        that.$message.error("请正确输入密码");
        return;
      }
      let SysID = window.localStorage.getItem("userId");
      that
        .$post("api/UserLogin/UpdatePassword", {
          SysID: SysID,
          OldPassword,
          Password,
        })
        .then(function (data) {
          if (data.Result) {
            that.$message({
              type: "success",
              message: "密码修改成功!",
            });
            that.i = 2;
            that.OldPassword = "";
            that.Password = "";
          } else {
            that.$message.error(data.Msg);
          }

          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 解绑微信
    openWeChat() {
      let that = this;
      let SysID = window.localStorage.getItem("userId");

      this.$confirm("请确认要解绑微信？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that
            .$post("/api/UserInfo/CancelWx", {
              SysID: SysID,
            })
            .then(function (data) {
              that.$message({
                type: "success",
                message: "解绑成功!",
              });
              console.log(data);
              that.getnewsList();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    getWeChat() {
      let that = this;
      that.i = 6;
      that.timeONce = setInterval(() => {
        let SysID = window.localStorage.getItem("userId");
        that
          .$post("/api/UserInfo/HaveWx", {
            SysID: SysID,
          })
          .then(function (data) {
            let i = that.i
            if (i!=6) {
              clearInterval(that.timeONce);
              return
            }
            console.log(data);
            let isWechat = data.Obj;
            that.isWechat = isWechat;
            if (data.Result) {
              if (data.Obj) {
                clearInterval(that.timeONce);
              }
            } else {
              that.$message.error(data.Msg);
              clearInterval(that.timeONce);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 6000);
    },

    getWeChatImg() {
      let userid = window.localStorage.getItem("userId");
      let WechatImg =
        "http://dbwx.dingbang.co/wechatfun/GenQRCode?userid=" + userid;
      this.WechatImg = WechatImg;
    },
    getComVIP() {
      this.$router.push("/companyCenter/addCompant");
    },
    // 企业标签
    getTags() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyTag",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyTags = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 判断是否微信绑定成功
    getweixin() {
      let that = this;
      let SysID = window.localStorage.getItem("userId");
      that
        .$post("/api/UserInfo/HaveWx", {
          SysID: SysID,
        })
        .then(function (data) {
          console.log(data);
          let isWechat = data.Obj;
          that.isWechat = isWechat;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业行业
    getProfession() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyProfession",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyProfession = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 企业富文本
    getEditor(value) {
      console.log(value);

      this.ruleForm.Description = value;
    },
    // 企业类型
    gettype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "NewsCompanyType",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyType = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取个人信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.UserObj;
           let isWechat = company.isWechat;
            that.isWechat = isWechat;
          that.ruleForm = company;
          let obj = data.Obj.CompanyObj;
          that.obj = obj;
          // 图片处理
          if (company.Logo) {
            company.Logo = that.urlOA + company.Logo.substr(1);
          }
          that.isCarre = data.Obj.BuildingObj.SysID;
          // that.imgFilesList.unshift(company.Logo);
          that.compObj = company;
          that.LogoList = [];
          that.LoadFiles(company.SysID);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //附件列表
    LoadFiles(sysid) {
      if (sysid == "") return;
      this.loading = true;
      let that = this;
      that
        .$post("/api/File/List", {
          MainSysID: sysid,
        })
        .then(function (res) {
          let List = [];
          List = res.Obj;

          List.forEach((i) => {
            that.LogoList.push({
              name: i["SourceName"] + "." + i["Extension"],
              url: that.urlOA + i["URL"].substr(1),
              size: -1,
              SysID: i.SysID,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //上传logo附件
    AddFileLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.LogoList = this.LogoList.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },
    //删除logo
    RemoveLogo(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.LogoList = this.LogoList.filter((v, i) => {
              return v != Obj;
            });
            this.DeleteImgSysID = Obj.SysID;
          } else {
            this.LogoList = this.LogoList.filter((v, i) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },

    // 编辑个人信息
    submitForm(formName) {
      let that = this;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          let ruleForm = that.ruleForm;
          // ruleForm.Tags = ruleForm.Tags.join(",");

          // let FileList = that.FileList;

          // 头像logo

          let LogoList = that.LogoList;
          if (LogoList.length == 0) {
            that.$message.error("请上传头像");
            return;
          }
          console.log(LogoList);
          let formData = new FormData();
          // formData.append("AccountLogo", FileList[0]);
          LogoList.forEach((file, i) => {
            formData.append("AccountLogo", file);
          });

          formData.append("Obj", JSON.stringify(ruleForm));
          formData.append("DeleteImgSysID", this.DeleteImgSysID);
          that.$post("/api/UserInfo/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑信息成功");

              that.getnewsList();
              that.i = 1;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消编辑
    cancleserve(i) {
      let that = this;
      that.i = i;
      that.item = {};
    },
    getindex() {
      this.$router.push("/");
      window.localStorage.setItem("index", "1");
    },
    gettags(i) {
      this.i = i;
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getcompany() {
      this.$router.push("/centerHome");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    // 上传用户图像
    uploadImg(file) {
      console.log(this.FileList);
      //   console.log(file);
      // let fileimg = file.data[0]
      //   if (fileimg.extension=='jpg'||fileimg.extension=='jpeg'||fileimg.extension=='png') {
      //      this.FileList = this.FileList.concat(file)
      //   }else{
      //        this.$Message.warning('上传附件格式有误,请重新上传!')
      //   }
    },
    maxUploadNum(file, FileList) {
      console.log(file);
      let imgLength = FileList.length,
        uploadBtn = document.getElementsByClassName("el-upload")[0];
      if (imgLength >= 1) {
        uploadBtn.style.display = "none";
      }
    },
    // 删除图片
    handleRemove(file, FileList) {
      let deleteID = null,
        imgLength = FileList.length,
        uploadBtn = document.getElementsByClassName("el-upload")[0];
      if (imgLength < 1) {
        uploadBtn.style.display = "inline-block";
      }
    },
  },

  mounted() {
    this.getnewsList();
    this.getTags();
    this.getProfession();
    this.gettype();
    this.getWeChatImg();
    // this.getweixin();
  },
  beforeDestroy() {
    //页面关闭时清除定时器
    clearInterval(this.timeONce);
  },
};
</script>


<style scoped  lang="scss">
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}

.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 6px 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
  .img {
    display: inline-block;
    height: 300px;
    width: 300px;
    margin-left: 50%;
    margin-top: 30%;
    transform: translate(-50%, -50%);
  }
  .imga {
    display: inline-block;
    height: 224px;
    width: 142px;
    margin-left: 50%;
    margin-top: 30%;
    transform: translate(-50%, -50%);
  }
}
.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  border-left: 3px solid #409EFF;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  // height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.userinforbox {
  width: 100%;
  // padding: 15px 0;
  border: 1px solid #d9edf7;
  box-sizing: border-box;
  overflow: hidden;
}
.username {
  float: left;
  width: 50%;
  line-height: 80px;
  font-size: 14px;
  padding: 0 15px;
  box-sizing: border-box;

  span {
    display: inline-block;
    width: 100px;
    color: #666;
  }
  i {
    display: inline-block;
    font-style: normal;
  }
}

.userlogo {
  height: 100px;
  padding: 0 15px;
  line-height: 100px;
  font-size: 14px;
  span {
    display: inline-block;
    width: 100px;
    color: #666;
  }
  img {
    height: 80px;
    width: 80px;
    vertical-align: middle;
    border-radius: 50%;
  // object-fit: cover;
  }
}
.upbox {
  margin-top: 10px;
  span {
    cursor: pointer;
    background-color: #50bfff;
    padding: 6px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;
  display: flex;
  justify-content: center;
  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.bottom_right::v-deep .el-form-item__content {
  width: 80%;

  margin-top: 60px;
  img {
    float: left;
    padding-bottom: 30px;
  }
  .delimg {
    float: left;
    margin-top: -15px;
    margin-left: -15px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 9999999999;
    border-radius: 50%;
    background-color: red;
  }
}
.el-icon-camera-solid:before {
  font-size: 24px;
}
@media (max-width: 500px) {
  .bottombox {
    width: 100%;
    min-height: 600px;
    margin-left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
    // background-color: aquamarine;
    overflow: hidden;
  }
  .bottom_left {
    float: left;
    // background-color: red;
    width: 100%;
    min-height: 60px;
  }
  .bottom_right {
    float: left;
    width: 100%;
    margin-left: 0;
    padding: 0 5px;
    background-color: #fff;
    border: 1px solid #eee;
    min-height: 600px;
    box-sizing: border-box;
    .img {
      display: inline-block;
      height: 300px;
      width: 300px;
      margin-left: 50%;
      margin-top: 53%;
      transform: translate(-50%, -50%);
    }
    .imga {
      display: inline-block;
      height: 224px;
      width: 142px;
      margin-left: 50%;
      margin-top: 30%;
      transform: translate(-50%, -50%);
    }
  }
  .tabbox {
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    // padding-bottom: 40px;
    box-sizing: border-box;
  }
  .tabtitle {
    display: none;
    padding-left: 30px;
    box-sizing: border-box;
    height: 50px;
    line-height: 55px;
    font-size: 16px;
  }
  .tabbtn {
    float: left;
    width: 50%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    padding-left: 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }
  .active {
  background-color: #409EFF;
  color: #fff;
  }
  .active1 {
    background-color: #e9e9e9;
  }
  .huanying {
    // height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    background-color: #d9edf7;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
  }
  .smalltitle {
    height: 36px;
    line-height: 6px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .userinforbox {
    width: 100%;
    // padding: 15px 0;
    border: 1px solid #d9edf7;
    box-sizing: border-box;
    overflow: hidden;
  }
  .username {
    float: left;
    width: 100%;
    line-height: 80px;
    font-size: 14px;
    padding: 0 15px;
    box-sizing: border-box;

    span {
      display: inline-block;
      width: 100px;
      color: #666;
    }
    i {
      display: inline-block;
      font-style: normal;
    }
  }

  .userlogo {
    height: 100px;
    padding: 0 15px;
    line-height: 100px;
    font-size: 14px;
    span {
      display: inline-block;
      width: 100px;
      color: #666;
    }
    img {
      height: 80px;
      width: 80px;
      vertical-align: middle;
      border-radius: 50%;
    }
  }
  .upbox {
    margin-top: 10px;
    span {
      cursor: pointer;
      background-color: #50bfff;
      padding: 6px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }
  .inforedint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #f1f1f1;
    span {
      cursor: pointer;
      background-color: #1890ff;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .password {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .left {
    flex: 3;
    .left_title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600;
      padding-left: 15px;
      box-sizing: border-box;
      color: #555;
    }
    .left_text {
      color: #555;
      line-height: 30px;
      font-size: 14px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    span {
      background-color: #1890ff;
      cursor: pointer;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .bottom_right::v-deep .el-form-item__content {
    width: 350px;
    margin-top: 0;
    img {
      float: left;
      padding-bottom: 30px;
    }
    .delimg {
      float: left;
      margin-top: -15px;
      margin-left: -15px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 9999999999;
      border-radius: 50%;
      background-color: red;
    }
  }
  .el-icon-camera-solid:before {
    font-size: 24px;
  }
}
</style>